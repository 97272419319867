.BlogFormBlock .Form__Title, .BlogFormBlock .Form__Element label.Form__Element__Caption {
  display: none;
}

.BlogFormBlock .Form__Description {
  margin-bottom: 2rem !important;
}

.BlogFormBlock section.Form__Element {
  row-gap: 1rem;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  gap: 0 10%;
}

.BlogFormBlock .FormTextbox > input {
  width: 100%;
}

.BlogFormBlock div.Form__Element {
  flex-grow: 1;
  justify-content: space-between;
  text-align: center;
  margin-bottom: 0.5rem !important;
}

@media (min-width: 768px) {
  .BlogFormBlock div.Form__Element {
    width: 45%;
  }
}

.BlogFormBlock .Form__Element.FormTextbox input {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  border-bottom-width: 1px;
  padding: .25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: .875rem;
  line-height: 1.25rem;
}

.BlogFormBlock textarea:focus, .BlogFormBlock input:focus{
  outline: none;
}

.BlogFormBlock .Form__Element.FormTextbox input::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}

@media (min-width: 1024px) {
  .BulletPointContentBlock .lg\:prose h1,
  .BulletPointContentBlock .lg\:prose h2,
  .BulletPointContentBlock .lg\:prose h3 {
    margin-top: 2.5rem;
    margin-bottom: 1rem;
  }

  .BulletPointContentBlock .lg\:prose h1::before,
  .BulletPointContentBlock .lg\:prose h2::before,
  .BulletPointContentBlock .lg\:prose h3::before {
    content: url(./star_icon_bright_violet.svg);
    position: absolute;
    margin-left: -4.5rem;
  }

  .BulletPointContentBlock .lg\:prose h1::before {
    transform: scale(2);
    margin-top: -1.325rem;
  }

  .BulletPointContentBlock .lg\:prose h2::before {
    transform: scale(1.5);
    margin-top: -0.25rem;
  }
}

.BulletPointContentBlock .lg\:prose>:first-child {
  margin-top: 0;
}

.BulletPointContentBlock .prose-sm h1,
.BulletPointContentBlock .prose-sm h2,
.BulletPointContentBlock .prose-sm h3 {
  margin-top: 0.75rem;
  margin-bottom: 0.5rem;
}
.search {
  --size: 40px;
}

.search input {
  margin-left: var(--size);
  width: calc(100% - var(--size));
}

@media (min-width: 1024px) {
  .search input {
    opacity: 0;
    width: 100%;
    cursor: pointer;
    margin-left: 0;
  }
  
  .search:focus-within input {
    z-index: initial;
    cursor: initial;
    opacity: 1;
    margin-left: var(--size);
    width: calc(100% - var(--size));
  }
}
.ContactInfoFormBlock .Form__Title, .ContactInfoFormBlock .Form__Description, .ContactInfoFormBlock .Form__Element label.Form__Element__Caption {
  display: none;
}

.ContactInfoFormBlock .Form__Element {
  margin: 0 !important;
}

.ContactInfoFormBlock section.Form__Element {
  row-gap: 1rem;
  display: grid;
}

@media (min-width: 768px) {
  .ContactInfoFormBlock section.Form__Element {
    row-gap: 3rem;
    -moz-column-gap: 8rem;
    column-gap: 8rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: auto [last-line]
  }

  .ContactInfoFormBlock section.Form__Element > *:nth-child(3n-1):nth-last-of-type(1) {
    grid-column: span 2;
  }
}

.ContactInfoFormBlock .Form__Element.FormTextbox input {
  --tw-border-opacity: 1;
  border-radius: .25rem;
  border-color: rgba(0,0,0,var(--tw-border-opacity));
  border-width: 1px;
  padding: .5rem .75rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: .875rem;
  line-height: 1.25rem;
}

.ContactInfoFormBlock .Form__Element.FormTextbox input::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
}

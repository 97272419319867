.Announcement a {
  color: white !important;
}

.Announcement p:nth-child(2) {
  margin-top: -14px;
}

.second-header{
 color: red;
 position: relative;
 /* display: block; */
 /* will-change : scroll-position ;  */
 /* position: -webkit-sticky; */
 top: 0;
 left: 0;
 right: 0;
}

/* makes the second-header have a full pixel height instead of leaving it to the font to calculate the height.
this fixes a small bug when 2 fixed elements are shown and the height is not a full height, causing a small half pixel gap between the fixed elements.
(for example: a height of 53.6px instead of 54px) */
.second-header p {
  min-height: 22px;
}

.sticky-header {
  position: fixed;
  /* top: 0; set through javascript */
  width: 100%;
  z-index: 2;
}


@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.nav-shadow-fuchsia {
  box-shadow: 0 2px 1px -1px #b1249c;
}

html {
  background-color: white;
}

.hidden-scrollbars::-webkit-scrollbar {
  display: none; /* Chrome/Safari/Webkit */
}

.hidden-scrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
}

.scrollbar::-webkit-scrollbar {
    width: 17px !important; /* Chrome/Safari/Webkit */
}

.scrollbar::-webkit-scrollbar-track {
    background-color: #fbfbfb;
}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: #B6B8FF;
    border-radius: 20px;
    border: 3.5px solid transparent;
    background-clip: content-box;
}

    .scrollbar::-webkit-scrollbar-thumb:hover {
        background-color: rgba(182, 184, 255, 0.75);
    }

.scrollbar {
    scrollbar-width: 17px !important; /* Firefox */
    -ms-overflow-style: scrollbar !important /* IE 10+ */
}


code, kbd, pre, samp {
  font-family: inherit;
  font-size: inherit;
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.blog-search-select {
  background: url(./caret.png) no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: 97%;
}

.EPiServerForms .hide {
  display: none !important;
}

.Form__Element.FormTextbox input:focus::placeholder {
  color: transparent;
}

@media (min-width: 768px) {
  .Form__Element.FormTextbox input {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.Form__Element.FormSubmitButton {
  --tw-text-opacity: 1;
  color: rgba(255,255,255,var(--tw-text-opacity));
  padding: .5rem 1.5rem;
  --tw-bg-opacity: 1;
  background-color: rgba(84,9,158,var(--tw-bg-opacity));
  border-radius: 9999px;
  font-size: .875rem;
}

.Form__Element.FormSubmitButton:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.Form__Element.FormSubmitButton:hover {
  background-color: rgba(50,7,103,var(--tw-bg-opacity));
}

@media (min-width: 768px) {
  .Form__Element.FormSubmitButton {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

.Form__Status__Message.Form__Success__Message {
  background-color: transparent !important;
  color: #54099E !important;
  font-size: 1.25rem;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

ol {
  list-style-type: decimal;
}

/* remove sticky functionality from mobile navigation element when viewing desktop */
@media (min-width: 1024px) {
  #mobileNavigation {
    position: relative !important;
  }
}

img:not([src]):not([srcset]) {
  visibility: hidden;
}
p img {margin: 0px!important;max-width:100%}
.left {float: left; padding-right: .5rem!important;
box-sizing:border-box;
  -webkit-box-sizing:border-box;
  -ms-box-sizing:border-box;
  -moz-box-sizing:border-box;}
.right {float: right; padding-left: .5rem!important;
box-sizing:border-box;
  -webkit-box-sizing:border-box;
  -ms-box-sizing:border-box;
  -moz-box-sizing:border-box;}
.padding-y-1 {
    padding-bottom: 0.25rem!important;
    padding-top: 0.25rem!important;
    margin-top: 1rem!important;}
@media (max-width: 767px) {
p img {max-width: 40%!important;}
.left {padding-left: .5rem!important;padding-bottom: .5rem!important;}
.right {padding-left: .5rem!important;padding-bottom: .5rem!important;}
.px-8 {padding-left: 0.5rem!important;padding-right: 0.5rem!important;}
.sm\:m-8 {max-width: 30%!important;}
.w-3\/4 {width: 45%!important;}
}

h1 img,h2 img,h3 img,h4 img,h5 img, h6 img {
  margin: 0px!important;
}

.prose-sm ul {
  @apply flex flex-col;
}

.markdown.intro-to-product-list {
    ul {
        display: block;
    }
}
.markdown a {text-decoration: underline !important;}
.markdown a:hover {text-decoration: none !important;}
.container {
    position: relative;
    min-height: 1.5em;
    min-width: 300px;
    border: 1px solid #777;
    display: flex;
    align-items: center;
    gap: 2.5em;
    padding: .5em;
    padding-left: 1.25em;
    padding-right: 1.25em;
    border-radius: 30px;
    outline: none;
    background-color: #8357DB;
}

.container:hover {
    cursor: pointer;
    background-color: rgb(50, 7, 103);
}

.container:focus {
    border-color: hsl(200, 100%, 50%);
}

.value {
    flex-grow: 1;
    display: flex;
    gap: .5em;
    flex-wrap: wrap;
    color: white;
}

.clear-btn {
    background: none;
    color: #777;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0;
    font-size: 1.25em;
}

.clear-btn:focus,
.clear-btn:hover {
    color: #333;
}

.divider {
    background-color: #777;
    align-self: stretch;
    width: .05em;
}

.caret {
    color: white;
}

.options {
    position: absolute;
    margin: 0;
    padding: 10px 0;
    list-style: none;
    display: none;
    max-height: 15em;
    overflow-y: auto;
    border: 1px solid #777;
    border-radius: .5em;
    width: 100%;
    left: 0;
    top: calc(100% + .25em);
    background-color: white;
    z-index: 100;
}

.options.show {
    display: block;
}

.option {
    padding: .25em .5em;
    cursor: pointer;
}

.option.selected {
    background-color: #D3D9FF;
}

.option.highlighted {
    background-color: #b8c1ff;
}

.option-badge {
    display: flex;
    align-items: center;
    border: .05em solid #777;
    border-radius: .25em;
    padding: .15em .25em;
    gap: .25em;
    cursor: pointer;
    background: none;
    outline: none;
}

.option-badge:hover,
.option-badge:focus {
    background-color: hsl(0, 100%, 90%);
    border-color: hsl(0, 100%, 50%);
}

.option-badge:hover>.remove-btn,
.option-badge:focus>.remove-btn {
    color: hsl(0, 100%, 50%);
}

.option-badge>.remove-btn {
    font-size: 1.25em;
    color: #777;
}
.override-ul-list ul {
    list-style-type: initial;
    padding: revert;
}

.option.purple-circle::before {
    content: '';
    position: absolute;
    background-color: #9966FF;
    width: 8px;
    height: 8px;
    top: calc(50% - 4px);
    left: 10px;
    border-radius: 50%;
}
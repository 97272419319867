/* START OF WELLBYCSO-105 specific styles to make the login popup button scale its available space like the normal RoundedButtonBlock next to it  */

.loginButtonSearchBar>div {
    height: 100%;
}

.loginButtonSearchBar>div [data-epi-edit="LoginPopupMemberNonmemberForm"] {
    width: 100%;
}

.loginButtonSearchBar>div [data-epi-edit="LoginPopupMemberNonmemberForm"] button {
    width: 100%;
}

@media (max-width: 640px) {
    .loginButtonSearchBar>div [data-epi-edit="LoginPopupMemberNonmemberForm"] button {
        font-size: 12px !important;
    }
}

/* END OF WELLBYCSO-105 specific styles */



/* START OF react-select specific styles */

.option-component:hover .checkbox-icon {
    color: white !important;   
}

/* END OF react-select specific styles */



/* START OF WELLBYCSO-271 show/hide autocomplete UI based on focus within the SearchInput UI */

.search-bar {

    .search-bar-input {

        .search-bar-input-text {

            &:not(:focus-within) {
                .search-bar-input-text-autocomplete {
                    display:none;
                }
            }

        }
    }
}

/* END OF WELLBYCSO-271 */
div.embedFormBlock.blog {
  width: 50%;
}
@media screen and (max-width: 768px) {
  div.embedFormBlock.blog {
    width: 100%;
  }
}
div.embedFormBlock form h3 {
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  font-size: 2.25rem;
  line-height: 2.5rem;
}
div.embedFormBlock form .hs-main-font-element {
  font-family: Euclid Circular A;
  font-weight: 400;
  font-size: 1.6rem;
  padding: 0 0 0 6px;
}
div.embedFormBlock form .hs-form-field {
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
  color: #430883;
  opacity: 1;
  padding: 2px 6px;
  margin-top: 14px;
}
div.embedFormBlock form .hs-form-field label {
  padding: 0 0.5rem;
  padding-bottom: 4px;
}
div.embedFormBlock form .hs-form-field legend {
  padding: 0 0.5rem;
}
div.embedFormBlock form .hs-form-field input, div.embedFormBlock form .hs-form-field select {
  height: 30px;
  width: 100%;
  padding: 0 0.5rem;
  border-bottom: 1px solid #9b9b9b;
  border-radius: 1px;
}
div.embedFormBlock form .hs-form-field input:focus, div.embedFormBlock form .hs-form-field select:focus {
  border-bottom: 2px solid #7C0DEB;
}
div.embedFormBlock form .hs-form-field input:focus-visible, div.embedFormBlock form .hs-form-field select:focus-visible {
  outline: none;
}
div.embedFormBlock form .hs-form-field input.error, div.embedFormBlock form .hs-form-field select.error {
  border-color: #EB0000;
}
div.embedFormBlock form .hs-form-field input::placeholder {
  color: #430883;
}
div.embedFormBlock form .hs-form-field input::placeholder.error {
  color: #EB0000;
}
div.embedFormBlock form .hs-error-msgs {
  flex: 0 0 100%;
  margin-top: 0.5em;
  color: #EB0000;
  font-weight: 700;
}
div.embedFormBlock form .hs-error-msgs .hs-error-msg, div.embedFormBlock form .hs-error-msgs .hs-main-font-element {
  font-size: 14.4px;
}
div.embedFormBlock form .hs-error-msgs label {
  padding: 0;
}
div.embedFormBlock form .hs-fieldtype-text label, div.embedFormBlock form .hs-fieldtype-phonenumber label {
  display: none;
}
div.embedFormBlock form .hs-fieldtype-text label.hs-error-msg, div.embedFormBlock form .hs-fieldtype-phonenumber label.hs-error-msg {
  display: block;
}
div.embedFormBlock form .hs-fieldtype-select {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
div.embedFormBlock form .hs-fieldtype-select label {
  padding-bottom: 0;
}
div.embedFormBlock form .hs-fieldtype-select .input {
  flex: 1 0;
}
div.embedFormBlock form .hs-fieldtype-select select {
  -webkit-appearance: none;
  background: url(https://www.wellbyfinancial.com/contentassets/a67363fd302a482ab8e71d61ceef2b1d/downarrow.png) no-repeat 100% #fff;
  background-position-x: 97%;
}
div.embedFormBlock form .hs-fieldtype-checkbox label {
  display: block;
  padding-left: 24px;
  text-indent: -16px;
}
div.embedFormBlock form .hs-fieldtype-checkbox input {
  vertical-align: middle;
  border: 1px solid white;
  height: 13px;
  width: 13px;
}
div.embedFormBlock form .hs-fieldtype-checkbox span {
  vertical-align: middle;
}
div.embedFormBlock form .hs-fieldtype-checkbox li span {
  margin-right: 12px;
  margin-left: 4px;
}
div.embedFormBlock form .hs-fieldtype-checkbox .input {
  margin-top: 8px;
}
div.embedFormBlock form .hs-fieldtype-checkbox .input ul {
  column-count: 2;
}
div.embedFormBlock form .hs-submit {
  margin-top: 14px;
  padding: 10px 2px;
}
div.embedFormBlock form .hs-submit .hs-button {
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  background-color: #54099e;
  color: white;
  border-radius: 9999px;
  cursor: pointer;
  padding: 0.72rem 2rem;
}
div.embedFormBlock form .hs-submit .hs-button:hover {
  background-color: #320767;
}
div.embedFormBlock form .hs-richtext {
  margin: 1em auto;
}
div.embedFormBlock form .legal-consent-container p {
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1rem;
  letter-spacing: -0.034rem;
  padding-left: 8px;
}

/* swiper needs the swiper-pagination-bullet class on each pagination element to be clickable.
so this unsets bullet pagination styles to allow for custom one to be clickable  */
.swiper .swiper-pagination-bullet {
    background: unset;
    border-radius: unset;
    display: unset;
    opacity: unset;
}

/* custom bullet styles */
.swiper .swiper-pagination .custom-bullet-active {
    width:45px;
    height:5px;
    border-radius: 10px;
    background-color: #55089E;
}

.swiper .swiper-pagination .custom-bullet {
    width:45px;
    height:5px;
    border-radius: 10px;
    background-color: #B6B8FF;
}

.custom-bullet:hover {
    cursor: pointer;
}

.swiper .swiper-button-prev,
.swiper .swiper-button-next {
    color: #55089E;
    background: rgb(255, 255, 255, 0.40);
    border-radius: 50%;
    width: 40px;
    height: 40px;
}

.swiper .swiper-button-prev::after,
.swiper .swiper-button-next::after
 {
    display: none;
}

.swiper .swiper-pagination {
    height: 15px;
}

/* these variables are used within swiper for style changes */
.swiper {
    --swiper-navigation-sides-offset: 50px;
    --swiper-navigation-size: 25px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .swiper {
        --swiper-pagination-bottom: 0px;
    }
}